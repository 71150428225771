


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Member extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public member: any = {};

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    this.$store.dispatch('loadUserById', {
      userId: this.id,
    }).then((response: any) => {
      this.member = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private updateStatus() {
    this.$store.dispatch('editUser', {
      userId: this.id,
      payload: {
        enabled: this.member.enabled,
      },
    }).then((response: any) => {
      if (response.enabled) {
        this.$q.notify('Le membre a été activé');
      } else {
        this.$q.notify('Le membre a été désactivé');
      }
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private _updatePermission() {
    this.$store.dispatch('editUser', {
      userId: this.id,
      payload: {
        permission: this.member.permission,
      },
    }).then((response: any) => {
      this.$q.notify('Permission changée');
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private updatePermission() {
    if (this.member.permission === 'admin') {
      this.$q.dialog({
        title: 'PERMISSION SENSIBLE',
        message: 'Vous allez donner les permissions super-administrateur à ce membre. \
        Il aura accès à toutes les organisations, membres, et analyseurs.',
        cancel: {
          label: 'Annuler',
        },
      }).onOk(() => {
        this._updatePermission();
      });
    } else {
      this._updatePermission();
    }
  }

  private sendResetPasswordEmail() {
    this.$q.dialog({
      title: 'Réinitialisation du mot de passe',
      message: 'Êtes-vous sûr de réinitialiser le mot de passe de l\'utilisateur ?',
      ok: {
        label: 'Oui, je réinitialise',
        outline: true,
      },
      cancel: {
        label: 'Non',
        outline: true,
      },
    }).onOk(() => {
      this.$store.dispatch('sendResetPasswordEmail', {
        userId: this.id,
      }).then((response: any) => {
        this.$q.notify('Email envoyé');
      }).catch((error: any) => {
        this.globalError(error);
      });
    });
  }

  private sendWelcomeEmail() {
    this.$store.dispatch('sendWelcomeEmail', {
      userId: this.id,
    }).then((response: any) => {
      this.$q.notify('Email envoyé');
    }).catch((error: any) => {
      this.globalError(error);
    });
  }
}
